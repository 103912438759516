import logo from './company.png';
import comingsoon from './comingsoon.png';
// import bg_img from './sydneynight.jpeg';

import './App.css';

function App() {
  return (
    <div className="App banner">
      <header className="App-header">
       <p>
       <img src={logo} width="100%" height="100%"/>
       </p>
        <p>
        <img src={comingsoon} width="170px" height="37px"/>
        </p>
      
      </header>
    </div>
  );
}

export default App;
